import React from "react";
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";
import { useCookies } from "react-cookie";
import Logo from "assets/images/svgs/logo.svg";
import * as S from "./Navbar.styles";

const Navbar = () => {
  const { formatMessage } = useIntl();
  const [, setCookie] = useCookies(["language"]);

  return (
    <S.Wrapper>
      <S.Container>
        <S.LogoWrapper href="#">
          <Logo aria-label="TM1" />
        </S.LogoWrapper>
        <S.MenuNav>
          <S.MenuLink to="#aboutUs">
            {formatMessage({ id: "navbar.aboutUs" })}
          </S.MenuLink>
          <S.MenuLink to="#whatWeDo">
            {formatMessage({ id: "navbar.whatWeDo" })}
          </S.MenuLink>
          <S.MenuLink to="#clients">
            {formatMessage({ id: "navbar.clients" })}
          </S.MenuLink>
          <S.MenuLink to="#cases">
            {formatMessage({ id: "navbar.cases" })}
          </S.MenuLink>
          <S.MenuLink to="/live-streaming">
            {formatMessage({ id: "navbar.liveStreaming" })}
          </S.MenuLink>
          <S.MenuLinkUrl href="https://tm1.gupy.io/" target="_blank">
            {formatMessage({ id: "navbar.work" })}
          </S.MenuLinkUrl>
          <S.MenuLink to="#contact">
            {formatMessage({ id: "navbar.contact" })}
          </S.MenuLink>
        </S.MenuNav>
        <S.LanguageWrapper>
          <IntlContextConsumer>
            {({ languages, language: currentLanguage }) =>
              languages.map((language) => (
                <S.LanguageButton
                  key={language}
                  active={currentLanguage === language}
                  onClick={() => {
                    setCookie("language", language, {
                      domain: "	.tm1.com.br",
                      path: "/",
                    });
                    changeLocale(language);
                  }}
                >
                  {language.toUpperCase()}
                </S.LanguageButton>
              ))
            }
          </IntlContextConsumer>
        </S.LanguageWrapper>
      </S.Container>
    </S.Wrapper>
  );
};

export default Navbar;
